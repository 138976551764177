<template>
  <div class="container">
    <nav class="navbar" :class="{ 'sticky': isSticky }">
      <div class="nav-brand">Renergico</div>
      <div class="flexEnd">
        <div class="nav-links">
          <a href="mailto:post@renergico.com" >{{
            navTranslation[currentLanguage].contactText
          }}</a>
        </div>
        <div class="language-select">
          <div
            class="flag"
            :style="{
              backgroundImage:
                currentLanguage === 'ES' ? 'url(es.png)' : 'url(gb.png)',
            }"
          ></div>
          <select
            id="language"
            v-model="currentLanguage"
            @change="changeLanguage"
          >
            <option value="EN">EN</option>
            <option value="ES">ES</option>
          </select>
        </div>
      </div>
    </nav>
    <div
      v-for="(section, index) in sections[currentLanguage]"
      :key="index"
      class="content"
      :style="{ backgroundImage: `url(${section.image})` }"
       :class="{ 'thirdText': index == 2} "
    >
      <div class="overlay" v-if="index === 0 || index == 2"></div>
      <div class="text-section" :class="{ 'white-text': index === 0 || index == 2} ">
        <div class="header">{{ section.title }}</div>
        <p
          v-for="(paragraph, pIndex) in section.text"
          :key="pIndex"
          v-html="paragraph"
        ></p>
        <a href="mailto:post@renergico.com">
        <button
          class="customButton"
          v-if="section.button"
          v-html="navTranslation[currentLanguage].contactText"
        ></button>
        </a>
      </div>
    </div>
  </div>
  <footer-component />
</template>

<script>
import footerComponent from "./components/footerComponent.vue";

export default {
  name: "App",
  components: {
    footerComponent,
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      navOpen: false,
      isSticky:false,
      currentLanguage: "EN",
      navTranslation: {
        EN: {
          contactText: "Contact us",
        },
        ES: {
          contactText: "Contáctenos",
        },
      },
      sections: {
        EN: [
          {
            title: "Welcome to Renergico",
            text: [
              "In Renergico's new alkaline battery recycling center, we use state-of-the-art equipment. The alkaline batteries are recycled in a safe and responsible way that benefits the environment.",
            ],
            image: "windpower.jpg"
            
          },
          {
            title: "Our Commitment to Sustainability",
            text: [
              "At Renergico sustainability is strongly integrated in the core of what we do. <br> Together with our customers and partners, our operations create value in the form of reduced climate impact as well as more efficient use of resources. <br/> <br/> But sustainability is not only about the climate and natural resources. It is also about taking care of each other – both at work and in society.",
            "For us, sustainability is about taking care of resources and leading the transition to a circular economy.  <br/> It is also about implementing a sustainable perspective in all aspects of our operations - striving for positive impact on both people, the planet, and the way we conduct our business.",
            ],
            image: "",
          },
          {
            title: "Safe & Sustainable Operations",
            text: [
              "Our recycling facilities are powered by renewable energy and our processes are energy efficient – making them safe for employees and the environment.",
            ],
            image: "lightbulb.jpg",
            button: true,
          },
          
        ],
        ES: [
          {
            title: "Bienvenido a Renergico",
            text: [
              "En el nuevo centro de reciclaje de baterías alcalinas de Renergico, utilizamos equipos de última generación. Las baterías alcalinas se reciclan de manera segura y responsable que beneficia al medio ambiente.",
            ],
            image: "windpower.jpg",
          },
          {
            title: "Nuestro Compromiso con la Sostenibilidad",
            text: [
              "En Renergico, la sostenibilidad está fuertemente integrada en el núcleo de lo que hacemos. Junto con nuestros clientes y socios, nuestras operaciones crean valor en forma de reducción del impacto climático y un uso más eficiente de los recursos. Pero la sostenibilidad no solo se trata del clima y los recursos naturales. También se trata de cuidarnos unos a otros, tanto en el trabajo como en la sociedad.",
              "Para nosotros, la sostenibilidad se trata de cuidar los recursos y liderar la transición hacia una economía circular. También se trata de implementar una perspectiva sostenible en todos los aspectos de nuestras operaciones, buscando un impacto positivo en las personas, el planeta y la forma en que llevamos a cabo nuestro negocio.",
            ],
            image: "",
          },
          {
            title: "Operaciones Seguras y Sostenibles",
            text: [
              "Nuestras instalaciones de reciclaje funcionan con energía renovable y nuestros procesos son energéticamente eficientes, lo que los hace seguros para los empleados y el medio ambiente.",
            ],
            image: "lightbulb.jpg",
            button: true,
          },
        ],
      },
    };
  },
  methods: {
    handleScroll() {
      // Determine the scroll position
      if (window.pageYOffset > 200) { // Adjust 100 to the scroll position where you want the navbar to become sticky
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleNav() {
      this.navOpen = !this.navOpen;
    },
    changeLanguage() {
      // Logic to handle language change if needed
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

.header {
  font-size: 5em;
  text-align: left;
  width: 90%;
}

.flag {
  width: 22px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
/*   background: #809848; */
background: #f6eeee;
  box-shadow: 2px 2px 5px #161414 !important;

  .content {
    &:first-child {
      .text-section {
        box-shadow: 2px 2px 5px #161414 !important;
      }
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #000;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: relative;
  z-index: 1000; /* Ensure the navbar stays above other content */
  transition: top 0.3s; /* Smooth transition for sticky effect */

  position: fixed;
  top:0;
  left:0;
  .flexEnd{
    padding-right:20px;
  }
}

.nav-brand {
  font-size: 24px;
  font-weight: bold;
  padding: 0 20px;
}

.nav-links {
  padding: 0 15px;
}

.language-select {
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  width: 22px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-top:5px;
  /* comment */
}

.language-select select {
  background-color: #2c3e50;
  color: white;
  border: 1px solid #8dc63f;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.language-select select:focus {
  outline: none;
}

.language-select option {
  background-color: white;
  color: black;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.language-select {
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-select select {
  background-color: #2c3e50;
  color: white;
  border: 1px solid #8dc63f;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.language-select select:focus {
  outline: none;
}

.language-select option {
  background-color: white;
  color: black;
}

.nav-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
}

.nav-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: white;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0 0 0;
  background-size: cover;
  background-position: center;
  justify-content: center;
  color: #000;
  text-align: center;
  min-height: 500px;
  &.thirdText{
      .overlay{
        opacity: 0.3;
      }
      p{
        max-width: 70%;
      }

  }
}
.container {
  .content:first-child .text-section {
    box-shadow: 2px 2px 5px #161414;
  }
}

.white-text {
  color: #fff; /* White text */
}

.white-text .overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
}

.text-section {
  max-width: 1000px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: flex-start;
  align-items:  flex-start;
  z-index: 10;
  min-height: 400px;
  padding:40px 0 60px 0;
}

.text-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 700;
}

.text-section p {
  font-size: 1.3em;
  text-align: left;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .text-section{
    max-width: 90%;
    .header{font-size: 3.5em;}
    &.thirdText{
      .overlay{
        opacity: 0.3;
      }
    .text-section{
      align-self: flex-start;
      margin-left:5%;
      width:70%;
    }
  }
  }

  .language-select{
    margin-right: 30px;
  }
  .text-section p {
    font-size: 1.2em;
  }
  .nav-brand{
    padding: 0;
  }
  .nav-links{
    padding-left:0;
  }
}

@media screen and (max-width: 480px) {
  .text-section h2 {
    font-size: 2em;
  }

  .text-section p {
    font-size: 1em;
  }

  .header {
    font-size: 2.5em;
  }

  .nav-brand {
    font-size: 20px;
  }

  .language-select select {
    font-size: 14px;
  }

  .flag {
    width: 18px;
    height: 10px;
    margin-right: 3px;
  }

  .nav-links a {
    font-size: 14px;
  }
}
.customButton {
  display:flex;

  font-size: 1.2em;
  font-weight: bold;
  background-color: transparent;
/*   border: 2px solid #8dc63f; */
text-decoration: underline;
color:#fff;
  border:none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.customButton:hover {
/*   background-color: #2980b9;
  border-color: #3498db; */
}

.customButton:focus {
  outline: none;
}

.customButton:active {
  transform: translateY(1px);
}

/* Style the text inside the link */
.customButton .bde-text-25-126 {
  display: inline-block;
  margin-right: 10px; /* Adjust spacing between text and icon */
}

/* Style the icon inside the link */
.customButton .bde-icon-25-348 {
  display: inline-block;
  vertical-align: middle; /* Align icon vertically with text */
}

</style>
